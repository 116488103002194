body {
  background-color: #fff;
  font-family: 'Jost', sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #191919;
  overflow-x: hidden;
  /* THEME COLORS */
  --color-theme-colors: ("primary": #E5283E,
      "secondary": #E5283E,
      "info": #A20F20);
  /* COLORES */
  --color-black: #000;
  --color-gray: #E5E5E5;
  --color-red: #E5283E;
  --color-redAlt: #A20F20;
  --color-beige: #E8C447;
}

a {
  text-decoration: none;
}

.dropdown-item {margin: 0; padding: 0 0 0 16px;}

#wol-navbar {
  padding: 0 4rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem 4rem;
}

.muted-text {color: white; opacity: 0.9;}

#homepage-jobs-container {
  margin-bottom: 3rem;
  font-family: 'Jost', sans-serif;
  font-weight: 400;
  line-height: 1.5em;
}

.listing-title-container {
  font-size: 24px;
  font-weight: 600;
}

#employer-landing-container {margin-top: 50px;}
#employer-premium-landing-container {margin-top: 50px;}

.employer-landing-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
}

.employer-landing-logo img{
  max-width: 200px;
  max-height: 200px;
}

.employer-landing-description {
  display: flex;
  align-items: center;
  text-align: justify;
  text-justify: inter-word;
}

#navbar-links {
  margin-left: 10%;
}

#navbar-links a {
  font-family: 'Open Sans', sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  line-height: 50px;
  color: #191919;
}

#navbar-links .nav-item {
  font-family: 'Open Sans', sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  padding: 16px 0 8px 8px;
}

.job-title-link a {
  color: #1F2A66;
}

.job-title-link a:hover {
  color: #1F2A66;
}

h1 {
  font-weight: 700;
  font-size: 54px;
  line-height: 64px;
  color: #1F2A66;
}

h2 {
  color: #1F2A66;
  font-size: 26px;
}

h5 {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #191919;
  vertical-align: top;
}

.navbar-toggler {
  border-color: #fff;
  margin-right: -40px;
}

.navbar-link {
  color: gray !important;
  padding: 15px 10px 0 10px;
}

a.nav-link {
  text-decoration: none;
}

#job-offer-description {
  font-size: 17px;
  margin-right: 35px;
  color: #191919;
}

#job-offer-content {
  margin-right: 35px;
}

#job-offer-employer-section{
  margin-top: 35px;
  margin-right: 35px;
  text-align: justify;
  text-justify: inter-word;
}

.job-offer-employer-logo {
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.job-offer-employer-logo img{
  max-width: 200px;
  max-height: 200px;
}

.job-offer-employer-description {
  display: flex;
  align-items: center;
}

#job-offer-banner {
  margin: 40px 0;
  padding: 0 15px ;
}

#job-offer-banner h2 {
  font-weight: 400;
  font-size: 38px;
  padding-right: 25px;
  color: #fff;
}

.job-offer-subtitle {
  color: #fff;
  font-size: 22px;
  font-weight: 700;
}

#job-offer-employer {
  margin-top: 40px;
  padding-left: 120px;
  margin-right: 60px;
  margin-bottom: 60px;
}

#employer-job-listing {
  width: 100%;
}

#share-buttons-container{
  size: 15px;
  margin: 10px 35px 0 0;
  color: #666666;
  font-weight: 700;
}

#share-buttons-container span{
  margin: 0 0 0 20px;
}

.carousel-image-container {
  max-height: 320px;
}

.btn-wol {
  background-color: #d9534f !important;
  border-color: #d9534f !important;
  color: #fff !important;
  text-decoration: none;
}

.btn-wol-homepage {
  font-size: 24px;
  background-color: #d9534f !important;
  border-color: #d9534f !important;
  color: #fff !important;
  text-decoration: none;
}

a.btn-wol-homepage:hover {
  font-size: 26px;
}

.homepage-banner-title {
  font-weight: 400;
  font-size: 30px;
  font-style: italic;
  color: #fff;
}

#featured-jobs-table {
  margin: 10px 0 10px 0;
  padding: 10px 0 10px 0;
  border-radius: 25px !important;
  background-color: #FFF;
  box-shadow: 6px 6px 6px 6px #C9C8C6;
}

#featured-jobs-table td {
  padding: 1.75rem;
}

#available-jobs-table td {
  padding: 1.75rem !important;
}

.navbar-brand{
  display: inline-block;
  height: 60px; 
  padding-top: 10px;
}

.navbar-employer {
  border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.mailchimp-subscribe-input {
  margin: 2px;
  font-size: 14px;
}

.scrollable-image {
  margin-top: 50px;
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}

#hunting-container h1 {
  color: #191919;
  font-size: 42px;
  padding: 15px;
}

#hunting-container h3 {
  color: #960F0F;
  font-size: 29px;
}

#hunting-container .hunting-landing-subtitle {
  padding: 5px;
  margin-top: 25px;
  color: #191919;
  font-size: 20px;
  font-weight: 500;
}

#hunting-container .hunting-landing-text {
  padding: 5px;
  margin-top: 20px;
  color: #191919;
}

.hunting-container-link {
  text-decoration: underline;
  color: #1F2A66;
  font-weight: 600;
}

.hunting-container-button {
  font-size: 22px;
  padding: 10px;
}

#hunting-container ol {
  margin-left: -20px;
}

#hunting-container ol li {
  margin-bottom: 10px;
}

#offers {
  width: 100%;
}

#mail-offers {
  background-color: var(--color-black);
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0;
  align-items: stretch;
  font-family: 'Jost', sans-serif;
  font-weight: 400;
}

#mail-offers-title,
.contact-form {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5rem 6rem;
}

#mail-offers-title {
  background-image: url(/src/images/oferta-imagen-col-1.png);
  position: relative;
  background-size: cover;
}

#mail-offers-title::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
}

#mail-offers-title-inner {
  display: flex;
  align-items: flex-end;
  position: relative;
}

#mail-offers-title h2 {
  color: #fff;
  font-size: 2.5rem;
  font-weight: 600;
  margin: 5rem 0;
}

.contact-form {
  background-image: url(/src/images/oferta-imagen-col-2.png);
  background-position: bottom;
  background-size: cover;
}

.contact-form form {
  width: 60%;
}

.contact-form .mc-field-group {
  background-color: #FFF;
  width: 100%;
  margin-bottom: 1rem;
  padding: 0.5em;
  border-radius: 5px;
}

.contact-form input {
  border: none;
  outline: none;
  width: 100%;
}

.contact-form .clear button {
  width: 100%;
}

.job-item {
  border: 1px solid #E5E5E5;
  color: var(--color-black);
  padding: 1.25rem;
  border-radius: 10px;
  transition: 0.2s;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;
  min-height: 200px;
}

.job-item img {
  max-width: 180px;
  max-height: 180px;
}

.disabled-offer-link {
  opacity: 0.4;
  pointer-events: none;
}

.show-offer {
  border: solid 1px var(--color-red);
  background-color: #FFF;
  border-radius: 20px;
  padding: 0.5rem 1rem;
  font-weight: 600;
  font-size: 12px;
  color: var(--color-red);
  outline: none;
  transition: 300ms;
  text-transform: uppercase;
  line-height: 1.5;
}

.offer-link:hover {
  text-decoration: none;
}

.job-item:hover {
  border-color: var(--color-beige);
  background-color: var(--color-beige);
}

.offer-link:hover .show-offer {
  background-color: var(--color-redAlt);
  color: var(--color-gray);
}

.job-title {
  font-weight: 600;
  font-size: 25px;
}

.job-employer {
  font-size: 14px;
}

.job-subtitle {
  font-weight: 600;
}

.job-info {
  width: 50%;
}

.job-title,
.job-subtitle {
  margin-bottom: 0.25rem;
}

.unblock-background {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.39);
  position: relative;
  margin-top: -180px;
}

.unblock {
  background-color: #FFF;
  display: flex;
  padding: 20px 40px;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0px 4px 30px 2px #d4d4d4;
  transition: color 500ms ease, transform 400ms ease;
}

.unblock-text {
  padding-left: 1.5rem;
}

.unblock-text span {
  font-size: 1.1rem;
}

.unblock-text p, .unblock-text p > span {
  font-size: 1.65rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
  word-wrap: break-word;
  line-height: 1em;
}

.unblock-text p > span#free {
  font-weight: bolder;
}

.unblock:hover {
  color: var(--color-redAlt);
  transform: translate(0, -5px);
}

#stats-section {
  background-image: url(/src/images/bg-llamado.png);
  line-height: 1.5em;
  font-family: 'Jost', sans-serif;
  padding: 0;
}

#stats-figures,
#stats-invitation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #FFF;
}

#stats-figures {
  padding: 6rem;
}

#stats-invitation {
  padding: 3rem;
  margin: 3rem;
}

#stats-figures {
  align-items: center;
}

#stats-invitation {
  align-items: flex-start;
}

#stats-figures-bg {
  content: '';
  position: relative;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, .3);
}

#stats-figures h3 {
  margin: 0;
  font-size: 50px;
  font-weight: 500;
}

#stats-figures span {
  margin-bottom: 3rem;
}

#stats-invitation {
  margin-left: 3rem;
}

#stats-invitation h3 {
  font-size: 2rem;
  font-weight: 600;
  margin: 3rem 0 1.5rem 0;
}

#stats-invitation a {
  background-color: var(--color-red);
  border: solid 1px var(--color-red);
  color: #fff;
  padding: 0.5rem 1.5rem;
  margin: 0.5rem 0 1.5rem 0;
  border-radius: 0.3rem;
  font-weight: 600;
  font-size: 1rem;
  cursor: pointer;
  word-break: normal;
  text-decoration: none;
  transition: 0.15s;
}

#stats-invitation a:hover {
  background-color: #ce192e;
  border-color: #ce192b;
}

#banner-background {
  font-family: 'Jost', sans-serif;
  background-color: #E5283E;
  background-size: cover;
  position: relative;
  height: 450px;
  width: 100%;
  padding: 0;
}

#banner {
  background: url(/src/images/bg-jumbotron.png) center bottom no-repeat;
  mix-blend-mode: darken;
  position: relative;
  height: 100%;
  width: 100%;
  opacity: 0.5;
}

#banner::before {
  content: '';
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 1;
}

#inner {
  padding: 0 4rem;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 1;
  opacity: 1;
}

h1 {
  color: white;
  font-weight: 600;
  font-size: 3.5rem;
  line-height: 1.2;
  margin: 3rem 0;
}

h1 .small-title {
  font-size: 2rem;
}

#banner-link {
  background-color: #FFF;
  padding: 0.5rem 1.5rem;
  margin: 0.5rem 0 1.5rem 0;
  border-radius: 0.3rem;
  font-weight: 600;
  font-size: 1rem;
  cursor: pointer;
  word-break: normal;
  transition: 0.15s;
  width: fit-content;
}

#banner-link a {
  color: #E5283E;
  text-decoration: none;
}

#banner-link a:hover {
  color: var(--color-redAlt);
}

#offers-tag,
#last-posts-tag {
  background-color: var(--color-beige);
  width: 300px;
  padding: 0.8rem 1rem 0.8rem 1rem;
  color: #fff;
  font-weight: 400;
  letter-spacing: 0.2rem;
  font-size: 0.9rem;
  display: inline;
  text-transform: uppercase;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  font-family: 'Jost', sans-serif;
}

#offers-tag h2,
#last-posts-tag h2 {
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.3em;
  font-size: 1rem;
  margin-bottom: 0;
  padding: 0.5rem 0;
}

#last-posts-tag, #offers-tag {margin-top: -25px;}

#unblock-link {
  text-decoration: none;
}

#blog {
  background-color: #E5E5E5;
  padding-bottom: 4rem;
}

.carousel-inner {
  padding-bottom: 3rem;
}

.carousel-control-prev,
.carousel-control-next {
  color: black;
  position: absolute;
  bottom: 0;
  max-height: 30px;
}

.carousel-control-prev {
  top: initial;
}

.carousel-control-next {
  right: 0;
  top: initial;
}

.carousel-indicators {
  color: yellow;
  position: absolute;
  bottom: 0;
  left: 30%;
  margin: 0;
  max-width: 40%;
}

#card-list {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.card {
  width: 31%;
}

.card-body {
  padding: 30px;
  font-family: 'Jost', sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  min-height: 200px;
}

.card-title {
  font-size: 13px;
  font-weight: 400;
}

.card-text {
  font-weight: 500;
  line-height: 1.2;
}

.card a {
  font-size: 11px;
  padding: 10px 20px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-top: auto;
}

#blog-mobile-section {
  display: none;
  justify-content: center;
}

#blog-mobile-section .card {
  width: 50%;
}

.homepage-navbar {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  font-family: 'Jost', sans-serif;
  font-weight: 400;
  margin-left: auto;
  color: #000;
}

#navbar-links .homepage-navbar a {
  font-family: 'Jost', sans-serif !important;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  color: #191919;
  padding: 0;
  margin-left: 2rem;
  line-height: 1.5;
}

.homepage-navbar .nav-item {
  padding: 0 !important;
  margin-left: 2rem;
}

.homepage-navbar .dropdown-basic,
.homepage-navbar #login-button {
  font-size: 15px;
  font-family: 'Jost', sans-serif !important;
  border: 1px solid var(--color-red);
  font-weight: 600;
  outline: none;
}

.homepage-navbar .dropdown-basic {
  color: var(--color-red);
}

#dropdown-session:hover,
#dropdown-session:focus {
  color: white;
  background-color: var(--color-red);
}

#dropdown-countries {
  border: none;
  box-shadow: none;
}

.homepage-navbar #login-button {
  background-color: var(--color-red);
}

.homepage-navbar #login-button:hover {
  background-color: var(--color-redAlt);
}

.dropdown-menu a {
  margin-left: 0 !important;
  padding-left: 1rem !important;
}

.homepage-footer {
  background-color: black;
  font-family: 'Jost', sans-serif;
  color: #FFF;
  margin: 0;
  padding: 1rem 0.5rem;
  display: flex;
  justify-content: center;
}

.homepage-footer div {
  width: 70%;
}

.open-to-hire-item {
  height: 200px;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.open-to-hire-item img {
  max-width: 200px;
  max-height: 200px;
}

@media (prefers-reduced-motion: no-preference) {}

@media only screen and (min-width: 1200px) {
  .navbar-brand {margin-left: 60px; margin-right: 60px;}
  .navbar-brand img {width: 100%;}
  #inner {padding-left: 125px;}
  #job-offer-banner-container {padding-left: 130px; padding-top: 25px; padding-bottom: 20px;}
  #share-buttons-container{size: 14px; margin: 20px 0 0 0; font-weight: 500;}
  #share-buttons-container span{margin: 0 0 0 20px;}
  #job-offer-description {padding-left: 140px;}
  #job-offer-content{padding-left: 140px;}
  #job-offer-employer-section{padding-left: 140px; margin-right: 60px;}
  #employers-open-to-hire {margin-left: 160px;}
  /* #employer-landing-container {margin-left: 160px;} */
  #employer-premium-landing-container {margin-left: 160px;}
}

@media only screen and (max-width: 1200px) {
  .navbar-brand {margin-left: 10px;}
  .navbar-brand img {width: 90%;}
  #inner {padding-left: 75px;}
  #inner h1 {font-size: 3rem;}
  #job-offer-banner-container {padding-left: 80px;}
  #share-buttons-container{size: 14px; margin: 20px 0 0 75px; font-weight: 500;}
  #share-buttons-container span{margin: 0 0 0 10px;}
  #job-offer-description {padding-left: 90px;}
  #job-offer-content{padding-left: 90px;}
  #job-offer-employer-section{padding-left: 90px;}
  #employers-open-to-hire {margin-left: 65px;}
  /* #employer-landing-container {margin-left: 65px;} */
  #employer-premium-landing-container {margin-left: 65px;}
}

@media only screen and (max-width: 992px) {
  .navbar-brand {margin-left: 0px;}
  .navbar-brand img {width: 80%;}
  #navbar-links {margin-left: 15px !important;}
  #inner {padding-left: 65px;}
  #inner h1 {font-size: 3rem;}
  #job-offer-banner-container {padding-left: 70px;}
  #share-buttons-container{size: 14px; margin: 20px 0 0 65px; font-weight: 500;}
  #share-buttons-container span{margin: 0 0 0 10px;}
  #job-offer-description {padding-left: 80px;}
  #job-offer-content{padding-left: 80px;}
  #job-offer-employer-section{padding-left: 80px;}
  #employers-open-to-hire {margin-left: 55px;}
  /* #employer-landing-container {margin-left: 55px;} */
  #employer-premium-landing-container {margin-left: 55px;}

  #navbar-links .nav-item {padding: 0; padding-bottom: 8px;}
  #job-offer-employer {padding-left: 60px;}
  #wol-home-banner-container {max-height: 350px; height: 350px;}
  #home-banner-title {margin-top: 25px; margin-left: 60px;}
  #navbar-links a {line-height: 25px;}
  #stats-section {background-position: center; background-size: cover;}
  #stats-invitation {padding: 0 3rem; margin: 0 0 2rem 0;}
  #stats-figures, #stats-invitation {align-items: center; color: #FFF;}
  #stats-invitation h3 {text-align: center;}
  #navbar-links .homepage-navbar a, .homepage-navbar .nav-item {margin: 0.5rem 0;}
  #dropdown-countries {padding-left: 0;}
  .homepage-footer div {width: 100%; text-align: center;}
  #blog-section {padding: 0;}
  .homepage-navbar {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

@media only screen and (max-width: 768px) {
  .navbar-brand {margin-left: -10px;}
  .navbar-brand img {width: 75%;}
  #navbar-links {margin-left: 5px !important;}
  #inner {padding-left: 50px;}
  #inner h1 {font-size: 3rem;}
  #job-offer-banner-container{padding-left: 55px;}
  #share-buttons-container{size: 13px; margin: 15px 0 0 50px; font-weight: 400;}
  #share-buttons-container span{margin: 0 0 0 5px;}
  #job-offer-description {padding-left: 60px;}
  #job-offer-content{padding-left: 60px;}
  #job-offer-employer-section{padding-left: 60px;}
  #employers-open-to-hire {margin-left: 35px;}
  /* #employer-landing-container {margin-left: 35px;} */
  #employer-premium-landing-container {margin-left: 35px;}

  .info-xs-hidden {display: none;}
  h1 .small-title {margin-bottom: 5rem;}
  #mail-offers {flex-direction: column;}
  #blog-section {display: none;}
  #blog-mobile-section {display: block;}
}

@media only screen and (max-width: 600px) {
  .navbar-brand {margin-left: -40px;}
  .navbar-brand img {width: 65%;}
  #navbar-links {margin-left: -25px !important;}
  #inner {padding-left: 25px;}
  #inner h1 {font-size: 2rem;}
  #job-offer-banner-container{padding-left: 25px;}
  #share-buttons-container{size: 12px; margin: 10px 0 0 20px; font-weight: 400;}
  #share-buttons-container span{margin: 0 0 0 5px;}
  #job-offer-description {padding-left: 30px;}
  #job-offer-content{padding-left: 30px;}
  #job-offer-employer-section{padding-left: 30px;}
  #employers-open-to-hire {margin-left: 5px;}
  /* #employer-landing-container {margin-left: 5px;} */
  #employer-premium-landing-container {margin-left: 5px;}

  #banner-background {height: 380px;}
  .listing-title-container {display: none;}
  h1 {font-size: 40px;}
  .unblock-background {margin-top: 0;}
  #list-job-7 {display: none;}
  #navbar-links {margin-left: 0;}
  #blog-mobile-section .card {width: 80%;}
}
